
<script setup>
    import { ref } from "vue";

    import MActionSheet from "mobile.vue.components.ActionSheet.vue";

    const show = ref(false);
</script>

<template>
    <div class="mt-3 ms-3 d-flex">
        <div class="flex-grow-1" style="min-width: 0;">
            <div class="d-flex justify-content-between align-items-stretch">
                <div class="font-n5 uppercase tracking-4 text-truncate" style="color: rgb(40%, 40%, 40%);">
                    <slot name="type" />
                </div>
                <!--
                <div style="color: rgb(60%, 60%, 60%); font-size: 0.8em;">
                    <span>ID </span>
                    <slot name="id" />
                </div>
                -->
            </div>
            <div class="font-2 fw-medium">
                <slot name="title" />
            </div>
        </div>
        <template v-if="$slots.actions">
            <div class="flex-shrink-0">
                <a class="flex-shrink-0 d-flex justify-content-center align-items-center" style="color: black; height: 3rem; width: 3.5rem; transform: color: black; text-decoration: none;" @click="show = !show">
                    <!--
                    <i class="far fa-ellipsis-v" style="font-size: 1.375em;" />
                    -->
                    <i class="bi bi-three-dots-vertical" style="font-size: 1.5em;"></i>
                    <!-- cache fix -->
                </a>
            </div>
        </template>
    </div>
    <MActionSheet v-model="show">
        <!--
        <template #header>
            <div class="p-3 d-flex justify-content-center border-bottom">
            Activity
            <slot name="id" />
            </div>
        </template>
        -->
        <slot name="actions" />
    </MActionSheet>
</template>
